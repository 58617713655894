import { useTranslation} from "react-i18next";

import {
    Card,
    Row,
    Col
} from 'react-bootstrap';





function Home(props) {
    const { t, i18n } = useTranslation();
    
  return (
    <>
      <main>
        <section id="home">
          <h2 className='font-face-ms'>{t('home.welcome')}</h2>
          <Row>
            <Col></Col>
            <Col md={6}>
          <div className="mx-auto font-face-ms card-text">
              <p>{t('home.text')}</p>
              <p>{t('home.text2')}</p>
              <p><i>{t('home.text3')}</i></p>
          </div>
          </Col>
          <Col></Col>
          </Row>
          </section>
      </main>
    </>
  );
}

export default Home;
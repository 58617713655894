import { useTranslation, Trans} from "react-i18next";
import {
    Card,
    Row,
    Col,
    Form,
    Badge,
    Table,
    OverlayTrigger,
    Tooltip
} from 'react-bootstrap';
import {useEffect, useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPersonDigging } from '@fortawesome/free-solid-svg-icons';
import Cookies from "universal-cookie";

function FormInput(props) {
    return (
        <Form.Group className="mb-3">
        <Form.Label htmlFor={props.for} className={props.className}>{props.desc}</Form.Label>
        <Form.Control name={props.name} type={props.type} value={props.value} className="form-control" id={props.for} onChange={props.onChange} disabled={props.disabled} />
        </Form.Group>);
}

function WMT() {
    const cookies = new Cookies();
    const { t } = useTranslation();
    const [wmtCode, setWMTCode] = useState("");
    const [wmt, setWMT] = useState(cookies.get('wmt'));
    const [passwordError, setPasswordError] = useState("");

    const validate = _ => {
        if (wmtCode === "karlskrona" || wmtCode === "Karlskrona") {
            cookies.set('wmt', true, {path: '/'});
            setWMT(true);
        } else {
            setPasswordError(t('wrongCode'))
        }
    }

    return (
        <main>
            <h2 className='font-face-ms'>WEDDING MANAGEMENT TEAM</h2>
            <Row>
                <Col></Col>
                <Col md={8}>
                    <p className="font-face-ms">Här finns info till dig som involverar dig i vårt bröllop samt mer detaljerad planering. <b>OBS! Detta är ett utkast.</b></p>
                </Col>
                <Col></Col>
            </Row>
            { wmt ? <div>
            <Row className="font-face-ms">
                
                <Col></Col>
                <Col md={6}>
                <iframe src="https://docs.google.com/spreadsheets/d/e/2PACX-1vQWwk_3ljpkkgYa9vInWn-v9V8KTqtdF6Bq7crJCm3PqO8sT0KD5cA1gzN2sWW2Eg2SPHZhn8WBqXJY/pubhtml?widget=true&amp;headers=false" width="800" height="2000" frameBorder="0" marginHeight="0" marginWidth="0"></iframe>
                    {/**
                    <h3 className="font-face-ms-bold">Våra Wedding Teams</h3>
                    <p>Vi uppskattar er hjälp med att göra vår bröllopsdag helt magisk extremt mycket! För att ha lite struktur så har vi delat in i olika arbetsgrupper och här kan ni se vilka som är med i vilken grupp för koordinering.</p>
                    <h4>Teamledare</h4>
                    <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip id="table1-tooltip">Tabell 1</Tooltip>}>
                    <Table>
                        <thead>
                            <tr>
                                <th>Grupp</th>
                                <th>Personer</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Helhet Fredag</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Helhet Lördag</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Mat</td>
                                <td>Magnus von Schenck, Malin Faraasen</td>
                            </tr>
                            <tr>
                                <td>Dekoration</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Toastmasters</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Foto & Film</td>
                                <td>Nadja Alsén (bröllopsfotograf), Andrea Taraldsson</td>
                            </tr>
                        </tbody>
                    </Table>
                    </OverlayTrigger>
                     <Table>
                        <thead>
                            <tr>
                                <th>Grupp</th>
                                <th>Medlemmar</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Foto & Film</td>
                                <td>Nadja Alsén, Joakim Franklin, Magnus von Schenck, Andrea Taraldsson</td>
                            </tr>
                            <tr>
                                <td>Mat Skärva</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Hotellansvarig Skärva</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Hotellansvarig Statt</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Toastmasters</td>
                                <td></td>
                            </tr>
                        </tbody>
            </Table>
                    <h4>Vigselceremoni</h4>
                    <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip id="table1-tooltip">Tabell 2</Tooltip>}>
                    <Table>
                        <thead>
                            <tr>
                                <th>Grupp</th>
                                <th>Medlemmar</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Vigselvärdar</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Musik</td>
                                <td>Emma Ekman - Sång, Casper von Schenck - Piano</td>
                            </tr>
                            <tr>
                                <td>Kyrkfoto</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Dekoration</td>
                                <td>Ingrid von Schenck</td>
                            </tr>
                            <tr>
                                <td>Vatten</td>
                                <td></td>
                            </tr>
                        </tbody>
                    </Table>
                    </OverlayTrigger>
                    <h4>Brudskål och Vigselfest</h4>
                    <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip id="table1-tooltip">Tabell 3</Tooltip>}>
                    <Table>
                        <thead>
                            <tr>
                                <th>Grupp</th>
                                <th>Medlemmar</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Foto & Film</td>
                                <td>Nadja Alsén</td>
                            </tr>
                            <tr>
                                <td>Lokalprepp</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Mat & Dryck</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Musik</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Servering</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Lokalstäd</td>
                                <td></td>
                            </tr>
                            </tbody>
                    </Table>
                    </OverlayTrigger>
                    <h4>Bröllopsmingel</h4>
                    <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip id="table1-tooltip">Tabell 4</Tooltip>}>
                    <Table>
                        <thead>
                            <tr>
                                <th>Grupp</th>
                                <th>Medlemmar</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Foto & Film</td>
                                <td>Nadja Alsén, Magnus von Schenck, Joakim Franklin, Andrea Taraldsson</td>
                            </tr>
                            <tr>
                                <td>Lokalprepp</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Mat & Dryck</td>
                                <td>Magnus von Schenck, Malin Faraasen</td>
                            </tr>
                            <tr>
                                <td>Musik</td>
                                <td>Olof Ellman</td>
                            </tr>
                            <tr>
                                <td>Servering</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Lokalstäd</td>
                                <td></td>
                            </tr>
                            </tbody>
                    </Table>
                    </OverlayTrigger>
                    <h4>Bröllopsfest</h4>
                    <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip id="table1-tooltip">Tabell 5</Tooltip>}>
                    <Table>
                        <thead>
                            <tr>
                                <th>Grupp</th>
                                <th>Medlemmar</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Foto & Film</td>
                                <td>Nadja Alsén, Andrea Taraldsson</td>
                            </tr>
                            <tr>
                                <td>Lokalprepp</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Mat & Dryck</td>
                                <td>Magnus von Schenck, Malin Faraasen</td>
                            </tr>
                            <tr>
                                <td>Musik</td>
                                <td>Olof Ellman, Anton Lysén, Anton Johansson, Herman Karlsson</td>
                            </tr>
                            <tr>
                                <td>Servering</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Lokalstäd</td>
                                <td></td>
                            </tr>
                            </tbody>
                        
                    </Table>
                    </OverlayTrigger>
                <h3 className="font-face-ms-bold">Schema</h3>
                <p>Observera att schemat i nuläget är högst preliminärt och kommer att uppdateras.</p>
                <h4>Torsdag 25/7</h4>
                <p>Denna dag kommer vi ha en genomgång av själva vigselakten i Fredrikskyrkan på Stortorget, och vi önskar då att alla som på något sätt är delaktiga i vigseln är på plats i Karlskrona för detta genrep.</p>
                <p>Efter genrepet kommer vi bjuda på middag hemma hos oss på Strandvägen 3!</p>
                <h4>Fredag 26/7</h4>
                <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip id="table1-tooltip">Tabell 6</Tooltip>}>
                <Table>
                    <thead>
                            <tr>
                                <th>Vad?</th>
                                <th>När?</th>
                                <th>Vilka?</th>
                                <th>Var?</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>07:00</td>
                                <td>Frukost med brud</td>
                                <td>Brudtärnor</td>
                                <td>Skärva Herrgård</td>
                            </tr>
                            <tr>
                                <td>07:00</td>
                                <td>Frukost med brudgummen</td>
                                <td>Brudgumstärnor</td>
                                <td>Skärva Herrgård</td>
                            </tr>
                            <tr>
                                <td>08:00</td>
                                <td>Hoppa i kläder för fotografering</td>
                                <td>Brud- och brudgumstärnor</td>
                                <td>Skärva Herrgård</td>
                            </tr>
                            <tr>
                                <td>10:00-14:00</td>
                                <td>Fotografering</td>
                                <td>Brud- och brudgumstärnor, Fotograferingsgruppen</td>
                                <td>TBD av Nadja</td>
                            </tr>
                            <tr>
                                <td>15:30</td>
                                <td>VIGSEL</td>
                                <td>Alla</td>
                                <td>Fredrikskyrkan</td>
                            </tr>
                            <tr>
                                <td>16:30</td>
                                <td>Fotografering</td>
                                <td>Nadja Alsén, utvalda andra</td>
                                <td>Saltö</td>
                            </tr>
                            <tr>
                                <td>17:00</td>
                                <td>Mingel på Statt</td>
                                <td>Alla andra</td>
                                <td>Saltö</td>
                            </tr>
                            <tr>
                                <td>18:00</td>
                                <td>Brudparet gör entré på Brudskålen</td>
                                <td>Alla</td>
                                <td>Statt</td>
                            </tr>
                            <tr>
                                <td>18:00 - 23:00</td>
                                <td>Vigselfest med inslag av dans</td>
                                <td>Alla</td>
                                <td>Statt</td>
                            </tr>
                        </tbody>
                </Table>
                </OverlayTrigger>
                <h4>Lördag 27/7</h4>
                <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip id="table1-tooltip">Tabell 7</Tooltip>}>
                <Table>
                    <thead>
                            <tr>
                                <th>Vad?</th>
                                <th>När?</th>
                                <th>Vilka?</th>
                                <th>Var?</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>07:00</td>
                                <td>Frukost med brud</td>
                                <td>Brudtärnor</td>
                                <td>Skärva Herrgård</td>
                            </tr>
                            <tr>
                                <td>07:00</td>
                                <td>Frukost med brudgummen</td>
                                <td>Brudgumstärnor</td>
                                <td>Skärva Herrgård</td>
                            </tr>
                            <tr>
                                <td>08:00</td>
                                <td>Hoppa i kläder för fotografering</td>
                                <td>Brud- och brudgumstärnor</td>
                                <td>Skärva Herrgård</td>
                            </tr>
                            <tr>
                                <td>10:00-14:00</td>
                                <td>Fotografering</td>
                                <td>Brud- och brudgumstärnor, Fotograferingsgruppen</td>
                                <td>TBD av Nadja</td>
                            </tr>
                            <tr>
                                <td>14:00</td>
                                <td>Bröllopsmingel</td>
                                <td>Alla</td>
                                <td>Skärva Herrgård, bakgården</td>
                            </tr>
                            <tr>
                                <td>14:00</td>
                                <td>Fotoväggen öppnar ute</td>
                                <td>Andrea Taraldsson</td>
                                <td>Skärva Herrgård, bakgården</td>
                            </tr>
                            <tr>
                                <td>14:00</td>
                                <td>Trubadur börjar spela</td>
                                <td>Olof Ellman</td>
                                <td>Skärva Herrgård, bakgården</td>
                            </tr>
                            <tr>
                                <td>14:30</td>
                                <td>Brudparet gör entré till minglet</td>
                                <td>Alla</td>
                                <td>Skärva Herrgård, framsida</td>
                            </tr>
                            <tr>
                                <td>16:30</td>
                                <td>Middagen börjar</td>
                                <td>Toastmasters</td>
                                <td>Kupolsalen</td>
                            </tr>
                            <tr>
                                <td>16:35</td>
                                <td>Brudparet gör entré på bröllopsmiddagen</td>
                                <td>Toastmasters</td>
                                <td>Kupolsalen, från ytterdörrarna</td>
                            </tr>
                            <tr>
                                <td>22:00</td>
                                <td>Första dansen</td>
                                <td>Toastmasters, Olof Ellman, Anton Lysén, Anton Johansson, Herman Karlsson</td>
                                <td>Kupolsalen</td>
                            </tr>
                            <tr>
                                <td>22:05</td>
                                <td>Bandet börjar spela sin setlist</td>
                                <td>Olof Ellman, Anton Lysén, Anton Johansson, Herman Karlsson</td>
                                <td>Kupolsalen</td>
                            </tr>
                            <tr>
                                <td>23:00</td>
                                <td>Shotluckan öppnar</td>
                                <td></td>
                                <td>TBD sidorum på Skärva</td>
                            </tr>
                            <tr>
                                <td>23:00</td>
                                <td>Fotoväggen öppnar inne</td>
                                <td></td>
                                <td>TBD, sidorum på Skärva</td>
                            </tr>
                            <tr>
                                <td>23:30</td>
                                <td>Fuldansen börjar</td>
                                <td></td>
                                <td>Kupolsalen</td>
                            </tr>
                            <tr>
                                <td>00:00</td>
                                <td>Shotluckan blir Korvluckan</td>
                                <td></td>
                                <td>TBD sidorum på Skärva</td>
                            </tr>
                        </tbody>
                </Table>
                </OverlayTrigger>
                 */}
                </Col>
                <Col></Col>
            </Row>
            <Row>
            </Row>
            <Row>
            </Row>
          </div> : 
          <div className="mb-5">
          <form onSubmit={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                }}>
                <Row>
                    <Col/>
                    <Col md={6}>
                        <FormInput className="font-face-ms" for="password" name="pass" desc="Lösen" type="text" value={wmtCode} onChange={event => setWMTCode(event.target.value)}/>
                    </Col>
                    <Col/>
                </Row>
                      {passwordError != null ? <Row>
                        <Badge pill bg="danger">
                            {passwordError}
                        </Badge>
                        </Row> : null}
                      <button type="button" className="btn btn-primary m-2 font-face-ms" onClick={validate}>{t('login')}</button>
            </form>
        </div>
    }
        
      </main>
    )
}

export default WMT;
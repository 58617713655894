import { useTranslation, Trans} from "react-i18next";
import {
    Card,
    Row,
    Col,
    Badge,
    Form,
    Button,
    FloatingLabel
} from 'react-bootstrap';
import {useEffect, useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPersonDigging } from '@fortawesome/free-solid-svg-icons'
import {fetchGuests, validateGuestCode} from "../api";
import Cookies from "universal-cookie";

function FormInput(props) {
    return (
        <Form.Group className="mb-3">
        <Form.Label htmlFor={props.for} className={props.className}>{props.desc}</Form.Label>
        <Form.Control name={props.name} type={props.type} value={props.value} className="form-control" id={props.for} onChange={props.onChange} disabled={props.disabled} />
        </Form.Group>);
}

function FloatingLabelFormInput(props) {
    return (
        <FloatingLabel
            controlId={props.controlId}
            label={props.desc}
            className={props.className}
            >
            <Form.Control type={props.type} min={props.min} max={props.max} placeholder={props.desc} onChange={props.onChange} disabled={props.disabled} value={props.value} as={props.as} rows={props.rows}/>
        </FloatingLabel>
    )
}

function RSVP(props) {
    const { t,i18n } = useTranslation();
    const [passwordError, setPasswordError] = useState("");
    const [password, setPassword] = useState("");
    const [weekendPassEntered, setWeekendPassEntered] = useState(false);
    const [fridayPassEntered, setFridayPassEntered] = useState(false);


    const validate = _ => {
        if (password === "kärleksfest" || password === "Kärleksfest" || password === "karleksfest" || password === "Karleksfest") {
            setWeekendPassEntered(true);
        } else if (password === "bröllop" || password === "Bröllop") {
            setFridayPassEntered(true);
        }
        else {
            setPasswordError(t('wrongCode'));
        }
    }

    return(
        <main>
            <h2 className="font-face-ms">{t('rsvp.header')}</h2>
            <Row>
            { weekendPassEntered || fridayPassEntered ? 
            (
                weekendPassEntered ? 
            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSe3Hdi0goc4ydFU4IuBFvpvj3Go3KEi0bjlyJBOf0cpKFXXYg/viewform?embedded=true" width="640" height="1803" frameBorder="0" marginHeight="0" marginWidth="0">Läser in …</iframe> :
            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScVBJW_bPooCiFrrXHeJWASNd2g-_uVo5h0fMV1AoiDj7rAjg/viewform?embedded=true" width="640" height="3709" frameBorder="0" marginHeight="0" marginWidth="0">Läser in …</iframe>
            ):

            <div className="mb-5">
                  <form onSubmit={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                        }}>
                        <Row>
                            <Col/>
                            <Col md={6}>
                                <FormInput className="font-face-ms" for="password" name="pass" desc={t('guestcode')} type="text" value={password} onChange={event => setPassword(event.target.value)}/>
                            </Col>
                            <Col/>
                        </Row>
                              {passwordError != null ? <Row>
                                <Badge pill bg="danger">
                                    {passwordError}
                                </Badge>
                                </Row> : null}
                              <button type="button" className="btn btn-primary m-2 font-face-ms" onClick={validate}>{t('login')}</button>
                    </form>
                </div>
                }
                </Row>
        </main>
    );
}

function OldRSVP (props) {
    const { t,i18n } = useTranslation();
    const [guestCode, setGuestCode] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [whoEnglish, setWhoEnglish] = useState();
    const [whoSwedish, setWhoSwedish] = useState();
    
    const validate = _ => {
        validateGuestCode(guestCode).then((result) => {
            props.cookies.set('guest', result.guest, {path: '/'});
            props.setGuest(result.guest);
            if(result.guest == null && result.passwordError) {
                setPasswordError(result.passwordError);
            } else if(result.guest == null) {
                setPasswordError(t('wrongCode'))
            }
        });
    }

    const [status, setStatus] = useState({attendanceStatus: props.guest && props.guest !== 'null' ? props.guest.will_attend : undefined});
    const { attendanceStatus } = status;

    const [needRoom, setNeedRoom] = useState({roomStatus: false});
    const { roomStatus } = status;

    const handleAttendanceChange = e => {
        e.persist();

        setStatus(prevState => ({
            ...prevState,
            attendanceStatus: e.target.value === 'true'
        }));
    };

    const handleRoomStatusChange = e => {
        e.persist();
        setStatus(prevState => ({
            ...prevState,
            roomStatus: e.target.value === 'friday' || e.target.value === 'both'
        }));
    }
   
    return (

        
        <main>
            <h2 className="font-face-ms">{t('rsvp.header')}</h2>
            {
                props.guest && props.guest !== 'null' ?
                <div>
                    
        <Card.Text as="div" className="font-face-ms">
            {
                !props.guest.answered ? t('rsvp.text') :
                    <div>
                    {t('rsvp.edit')}<br/>
                    {
                        t('rsvp.reply')}{props.guest.will_attend ?
                        <Badge bg="success">{t('rsvp.positive')}</Badge> :
                        <Badge bg="danger">{t('rsvp.negative')}</Badge>
                    }
                    </div>
             }
        </Card.Text>
                
              <Form className="font-face-ms" onSubmit={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                }}>
                    <Row>
                    <Col/>  
                    <Col md={6} className="justify-left">
                        <FloatingLabelFormInput className="font-face-ms-bold mb-3" for="firstname" name="firstname" desc={t('rsvp.firstname')} type="text" value={props.guest.firstname} disabled={true} controlId="firstName"/>
                        <FloatingLabelFormInput className="font-face-ms-bold mb-3"  for="lastname" name="lastname" desc={t('rsvp.lastname')} type="text" value={props.guest.lastname} disabled={true} controlId="lastName"/>
                        
                        <Form.Group className="font-face-ms-bold" controlId="attendanceStatus">
                            <Form.Check inline value={true} onChange={handleAttendanceChange} checked={attendanceStatus === true} type="radio" id="kommer" aria-label="radio 1" label={t('rsvp.positive')}/>
                            <Form.Check inline value={false} onChange={handleAttendanceChange} checked={attendanceStatus === false} type="radio" id="kommer_inte" aria-label="radio 2" label={t('rsvp.negative')}/>
                        </Form.Group>

                        <FloatingLabelFormInput className="font-face-ms-bold mb-3" for="email" desc="Email" type="email" controlId="email" disabled={!attendanceStatus}/>
                        <FloatingLabelFormInput className="font-face-ms-bold mb-3" for="phone-number" desc={t('rsvp.phone')} type="text" controlId="phone" disabled={!attendanceStatus}/>

                          <Form.Label htmlFor="attendance_group" className="font-face-ms-bold">{t('rsvp.attend_label')}</Form.Label>
                        <Form.Group id="attendance_group">
                            <Form.Check name="attendance"  id="all-weekend" type="radio" label={t('rsvp.all_weekend')} disabled={!attendanceStatus}/>
                            <Form.Check name="attendance" id="only-friday" type="radio" label={t('rsvp.only_friday')} disabled={!attendanceStatus}/>
                            <Form.Check name="attendance"  id="only-saturday" type="radio" label={t('rsvp.only_saturday')} disabled={!attendanceStatus}/>
                        </Form.Group>

                        <Form.Label htmlFor="accommodation_group" className="font-face-ms-bold">{t('rsvp.acc_label')}</Form.Label>
                        <Form.Group id="accommodation_group">
                            <Form.Check type="radio" name="accommodation" value={"friday"}  onChange={handleRoomStatusChange} id="answer-all-weekend" label={t('rsvp.acc_all_weekend')} disabled={!attendanceStatus}/>
                            <Form.Check type="radio" name="accommodation" value={"both"} onChange={handleRoomStatusChange} id="answer-only-part" label={t('rsvp.acc_fri_sun')} disabled={!attendanceStatus}/>
                            <Form.Check type="radio" name="accommodation" value={"none"} onChange={handleRoomStatusChange} id="answer-own" label={t('rsvp.acc_own')} disabled={!attendanceStatus}/>
                        </Form.Group>
                        
                        <FloatingLabelFormInput className="font-face-ms-bold mb-3" desc={t('rsvp.acc_people')} type="number" min={1} max={3} controlId="numPeople" disabled={!attendanceStatus || !roomStatus}/>
                        
                        <Form.Label htmlFor="food_group" className="font-face-ms-bold" >{t('rsvp.preferences_label')}</Form.Label>
                        <Form.Group id="food_group">
                            <Form.Check name="food-preferences" id="vegetarian" type="radio" label="Vegetarian" disabled={!attendanceStatus}/>
                            <Form.Check name="food-preferences" id="vegan" type="radio" label="Vegan" disabled={!attendanceStatus}/>
                        </Form.Group>
                        
                        <Form.Label htmlFor="alcohol_group" className="font-face-ms-bold">{t('rsvp.alc_pref_label')}</Form.Label>
                        <Form.Group id="alcohol_group">
                            <Form.Check type="radio" name="alcohol" id="answer-alcohol" label={t('rsvp.alcohol')} disabled={!attendanceStatus}/>
                            <Form.Check type="radio" name="alcohol" id="answer-not-alcohol" label={t('rsvp.non_alcohol')} disabled={!attendanceStatus}/>
                        </Form.Group>

                        
                      <FloatingLabelFormInput className="font-face-ms-bold mb-3" desc={t('rsvp.allergies')} as="textarea" controlId="allergies" disabled={!attendanceStatus}/>
                  
                      <Button variant="primary" type="submit" className="btn btn-primary m-2" >{t('rsvp.save')}</Button>
                      </Col>
                  
                  <Col/>
                  </Row>
                  </Form>
                  
                </div>
                :
                <div className="mb-5">
                  <form onSubmit={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                        }}>
                        <Row>
                            <Col/>
                            <Col md={6}>
                                <FormInput className="font-face-ms" for="password" name="pass" desc={t('guestcode')} type="text" value={guestCode} onChange={event => setGuestCode(event.target.value)}/>
                            </Col>
                            <Col/>
                        </Row>
                              {passwordError != null ? <Row>
                                <Badge pill bg="danger">
                                    {passwordError}
                                </Badge>
                                </Row> : null}
                              <button type="button" className="btn btn-primary m-2 font-face-ms" onClick={validate}>{t('login')}</button>
                    </form>
                </div>
            }

        </main>
    )

}

export default RSVP;
import { useTranslation} from "react-i18next";

import {
    Card,
    Row,
    Col,
    Table,
    Button
} from 'react-bootstrap';





function Blog(props) {
    const { t, i18n } = useTranslation();
    
  return (
    <>
      <main>
        <section id="blog">
          <h2 className='font-face-ms'>{t('blog.header')}</h2>
          <Row>
            <Col></Col>
            <Col md={6}>
          <div className="mx-auto font-face-ms card-text">
          <Table>
                <tbody>
                  <tr>
                    <td>
                      <b>2024-08-01</b>
                    </td>
                    <td><b>Kära vänner & familj</b><br/>
                    Vi svävar fortfarande på moln efter vår magiska bröllopshelg! Det var ni, våra älskade gäster, som gjorde den till ett minne för livet. Vilken fest, vilken glädje, vilken kärlek!<br/><br/>
Orden räcker inte för att uttrycka hur tacksamma vi är för att ni var där och delade vår stora dag. Era leenden, skratt, kramar, tårar och dansmoves värmde våra hjärtan och fyllde helgen med ren och skär lycka. Tack för allt ni gjort för oss, för all kärlek, pepp och stöd ni alltid ger oss. Ni är helt enkelt bäst! <br/><br/>
Vi har skapat ett gemensamt album där vi hoppas att ni vill dela alla era fantastiska bilder och videos från helgen. Ja, vi vill se allt från fula toaselfies och fuldansvideos till snygga babes och vigselvideos! Ju fler minnen vi kan samla, desto bättre. Här är länken:<br/>
<a href="https://evental.co/events/I6TuaPNq3lZPEOhBAvM8">https://evental.co/events/I6TuaPNq3lZPEOhBAvM8</a><br/><br/>
Några av er har frågat om hur ni kan bidra till vår bröllopsresa, och vi är så tacksamma för er omtanke. Om ni vill ge oss en liten gåva till reskassan är vårt Swish-nummer 0700472328. Men kom ihåg, er närvaro på bröllopet var den finaste gåvan vi kunde önska oss!<br/><br/>
Återigen, tack för att ni är ni. Vi är så lyckliga över att ha er i våra liv. Nu är vi herr och fru von Schenck, och det är tack vare er!<br/><br/>
Puss och kram,<br/>
Felicia & Ludvig von Schenck
                    </td>
                  </tr>
                </tbody>
              </Table>
          <Table>
                <tbody>
                  <tr>
                    <td>
                      <b>2024-07-23</b>
                    </td>
                    <td><b>Hjälp – Var ska jag vara, och när??</b><br/>
                    Ibland kan det kännas skönt om någon berättar för en var man ska befinna sig vid olika tidpunkter!<br/>
                    Vi har därför gjort en samling med Bröllopsscheman samt en lista över de gäster som tagit på sig ett ansvarsområde.<br/>
                    <a href="https://tinyurl.com/4mvdh86t">Till Bröllopsschemat!</a>
                    </td>
                  </tr>
                </tbody>
              </Table>
          <Table>
                <tbody>
                  <tr>
                    <td>
                      <b>2024-07-09</b>
                    </td>
                    <td>Sista dagen att anmäla tal eller andra framträdanden till bröllopet är <b>idag!!</b> <br/>
                    Se till att maila toasts på <b>toast.felicialudvig@gmail.com</b> om ni vill framföra något.<br/>
                    Flera gäster redan anmält ett intresse genom OSA. Den ”riktiga” talanmälan görs till toastmejlen ovan innehållande:
                    <ul> 
                    <li>
                      Hur du/ni vill bli presenterade, gärna på ett litet spexigt och roligt sätt liknande det ni skrev om er själva i OSA.</li>
                    <li>
                      En låt som ni vill gå upp till scen/micken till.
                    </li>
                    </ul>
                    Maxtid för tal är <b>3-4 minuter.</b> Spex får några minuter extra.<br/>
                    Vi kommer dela upp tal & spex mellan fredagen och lördagen, så alla som önskar framföra är välkomna
                    </td>
                  </tr>
                </tbody>
              </Table>
          <Table>
                <tbody>
                  <tr>
                    <td>
                      <b>2024-07-08</b>
                    </td>
                    <td>Gå in på sidan om <a href="/accommodation">boende</a> och se den nya informationen! <br/>Vilket gäng vi blir! Vi är så taggade!!</td>
                  </tr>
                </tbody>
              </Table>
              <Table>
                <tbody>
                  <tr>
                    <td>
                      <b>2024-04-24</b>
                    </td>
                    <td>{t('blog.bra')}<br/><br/>
                    <a href="https://www.flygbra.se/ica">ICA Stammis</a></td>
                  </tr>
                </tbody>
              </Table>
              <Table>
                <tbody>
                  <tr>
                    <td>
                      <b>2024-03-31</b>
                    </td>
                    <td>{t('blog.rsvp')}<br/><br/>
                    <Button href="/rsvp" >{t('rsvp.header')}</Button></td>
                  </tr>
                </tbody>
              </Table>
          </div>
          </Col>
          <Col></Col>
          </Row>
          </section>
      </main>
    </>
  );
}

export default Blog;
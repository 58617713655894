const apiUrl = "https://luddeflisanwedding.herokuapp.com/api";
//const apiUrl = "http://127.0.0.1:5000/api";

function fetchGuests() {
    const requestOptions = {
        headers: {'Content-Type': 'application/json', 'x-api-key': 'swagyolo'}
    };

    let guests = [];
    let errorMessage = null;
    let isLoaded = false;

    return fetch(apiUrl + '/guest', requestOptions).then(async response => {
        const data = await response.json();

        if(!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
        }
        guests = [].concat(data.guests.slice()).sort((a,b) => a.id > b.id ? 1 : -1)
        isLoaded = true

        return Promise.resolve({
            isLoaded: isLoaded,
            guests: guests,
            errorMessage: errorMessage
        });


    }).catch(error => {
        errorMessage = error.message;
        return Promise.resolve({
            isLoaded: isLoaded,
            guests: guests,
            errorMessage: errorMessage
        });
    });
}

function validateGuestCode(code) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json', 'x-api-key': 'swagyolo'},
        body: JSON.stringify({
            'guest_code': code
        })
    };

    let passwordError = "";
    let guest = null;

    return fetch(apiUrl + '/login', requestOptions).then(async response => {
       const data = await response.json();

       if(!response.ok) {
           const error = (data && data.message) || response.status;
           return Promise.reject(error);
       }

       guest = data.guest;
        console.log(guest);
       return Promise.resolve({
           guest: guest,
       })

    }).catch(error => {
        passwordError = error.message;
        return Promise.resolve({
            guest: guest,
            passwordError: passwordError
        })
    });
}

export {fetchGuests, validateGuestCode};
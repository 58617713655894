import {useEffect, useState} from "react";
import { Container } from "react-bootstrap";
import { useTranslation} from "react-i18next";

const calculateTimeLeft = () => {
    let end = new Date('07/26/2024 15:00');
    const difference = +end - +new Date();

    let timeLeft = {};

    if (difference > 0) {
        timeLeft = {
            days: Math.floor(difference /(1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) %24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60)
        };
    }

    return timeLeft;
}

function Countdown() {
    const { t, i18n } = useTranslation();
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => {
              clearTimeout(timer);
            };
    });

    return(
        <Container fluid className="footer-text">
            <h3 className="font-face-ms">{t('celebrate')}</h3>
            <h1 className='font-face-ms'>{t('days', {count: timeLeft.days})}, {t('hours', {count: timeLeft.hours})}, {t('minutes', {count: timeLeft.minutes})}, {t('seconds', {count: timeLeft.seconds})}</h1>
            </Container>
    );
}

export default Countdown;
import { useTranslation, Trans} from "react-i18next";
import {
    ButtonGroup,
    Card,
    ToggleButton,
    Image,
    Tabs,
    Tab,
    Table,
    Stack,
    Row,
    Badge,
    Col,
    Container, Form, Button,
    Carousel
} from 'react-bootstrap';
import aspo from './../img/aspo2.png';
import kyrkan from './../img/kyrkan2.png';
import skarva_mindre from './../img/skarva_dukning.jpg';
import sommarbrunch from './../img/sommarbrunch2.png'
import strandis from './../img/strandis.png';
import { VerticalTimeline, VerticalTimelineElement} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChurch, faChampagneGlasses, faChevronLeft, faChevronRight, faMartiniGlass } from '@fortawesome/free-solid-svg-icons';



function Schedule() {
    const { t } = useTranslation();
    return (
        <main>
        <Container fluid>
            
            <section >
                <h2 className='font-face-ms' id="schedule"> {t('schedule.header')}</h2>
                <div className='font-face-ms mx-auto card-text'>
                    <p>{t('schedule.ingress')}</p>
                </div>
            <Row>
                <Col sm/>
                <Col sm={6}>
                <TimelineSchedule/>
                </Col>
                <Col sm/>
            </Row>
            </section>
            {/*<CarouselSchedule/>*/} 
            
        </Container>
        </main>
    )
}

function NextIcon() {
    return (<FontAwesomeIcon icon={faChevronRight} size='2xl' style={{color: '#ffffff', width: '4rem',}}/>);
}

function PrevIcon() {
    return (<FontAwesomeIcon icon={faChevronLeft} size='2xl' style={{color: '#ffffff', width: '4rem'}}/>);
}

function TimelineSchedule() {
    const { t } = useTranslation();
    return (

    <VerticalTimeline layout='1-column-left'>
        <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentArrowStyle={{borderRight: '7px solid rgb(33, 150, 243)'}}
            iconStyle={{background: '#fff', color:'rgb(33, 150, 243'}}
            icon= {<FontAwesomeIcon icon={faChurch}/>}
        >
            <h3 className="vertical-timeline-element-title font-face-ms-bold">{t('schedule.fri_act1')}</h3>
            <h4 className="vertical-timeline-element-subtitle font-face-ms">{t('schedule.church')}</h4>
            <h5 className="vertical-timeline-element-subtitle font-face-ms">{t('schedule.fri_act1_location')}</h5>
            
            <br/>
            <Row>
            <h4 className="vertical-timeline-element-subtitle font-face-ms">{t('schedule.friday')} {t('schedule.fri_date')}, {t('schedule.fri_act1_time')}</h4>
                
            </Row>

        </VerticalTimelineElement>
        <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentArrowStyle={{borderRight: '7px solid rgb(33, 150, 243)'}}
            iconStyle={{background: '#fff', color:'rgb(33, 150, 243'}}
            icon= {<FontAwesomeIcon icon={faChampagneGlasses}/>}
        >
            <h3 className="vertical-timeline-element-title font-face-ms-bold">{t('schedule.fri_act2')}</h3>
            <h4 className="vertical-timeline-element-subtitle font-face-ms">{t('schedule.fri_act2_location')}</h4>
            <h5 className="vertical-timeline-element-subtitle font-face-ms">{t('schedule.fri_act1_location')}</h5>
            <br/>
            <Row className="font-face-ms">
                <h4 className="vertical-timeline-element-subtitle font-face-ms">{t('schedule.friday')} {t('schedule.fri_date')}, {t('schedule.fri_act2_time')}</h4>

            </Row>

        </VerticalTimelineElement>
        <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentArrowStyle={{borderRight: '7px solid rgb(33, 150, 243)'}}
            iconStyle={{background: '#fff', color:'rgb(33, 150, 243'}}
            icon= {<FontAwesomeIcon icon={faMartiniGlass}/>}
        >
            <h3 className="vertical-timeline-element-title font-face-ms-bold">{t('schedule.sat_act')}</h3>
            <h4 className="vertical-timeline-element-subtitle font-face-ms">{t('schedule.sat_act_location')}</h4>
            <h5 className="vertical-timeline-element-subtitle font-face-ms">{t('schedule.sat_act_location2')}</h5>
            <br/>
            <Row className="font-face-ms">
                <h4 className="vertical-timeline-element-subtitle font-face-ms">{t('schedule.saturday')} {t('schedule.sat_date')}, {t('schedule.sat_act_time')}</h4>
            </Row>

        </VerticalTimelineElement>
        {/**
        <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentArrowStyle={{borderRight: '7px solid rgb(33, 150, 243)'}}
            iconStyle={{background: '#fff', color:'rgb(33, 150, 243'}}
            icon= {<FontAwesomeIcon icon={faMugHot}/>}
        >
            <h3 className="vertical-timeline-element-title font-face-ms-bold">{t('schedule.sunday')} {t('schedule.sun_date')}</h3>
            <h4 className="vertical-timeline-element-subtitle font-face-ms">{t('schedule.sun_act')}</h4>
            <p className="font-face-ms">
                {t('schedule.sun_act_time')}<br/>
                {t('schedule.sun_act_location')}
            </p>

        </VerticalTimelineElement>
         */}
    </VerticalTimeline>
    )
}


function CarouselSchedule() {
    const { t } = useTranslation();
  return (
    <>
        <Row>
            <Col></Col>
            <Col md={8}>
        <Carousel className='font-face-ms' nextIcon={<NextIcon/>} prevIcon={<PrevIcon/>}>
            {/*
            <Carousel.Item>
                <a href='/construction'>
                <Image fluid="True" src={aspo}/>
                </a>
                <Carousel.Caption>
                    <h3>{t('schedule.wednesday')}</h3>
                    <p>{t('schedule.wed_desc')}</p>
                </Carousel.Caption>
            </Carousel.Item>
            
            <Carousel.Item>
                <a href='/construction'>
                <Image fluid="True" src={strandis}/>
                </a>
                <Carousel.Caption>
                    <h3>{t('schedule.thursday')}</h3>
                    <p>{t('schedule.thurs_desc')}</p>
                </Carousel.Caption>
            </Carousel.Item>
            */}
            <Carousel.Item>
            <a href='/construction'>
                <Image fluid="True" src={kyrkan}/>
                </a>
                <Carousel.Caption>
                    <h3 className="font-face-ms-bold">{t('schedule.friday')}</h3>
                    <p className="font-face-ms">{t('schedule.fri_act1')} + {t('schedule.fri_act2')}</p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
            <a href='/construction'>
                <Image fluid="True" src={skarva_mindre}/>
                </a>
                <Carousel.Caption>
                    <h3 className="font-face-ms-bold">{t('schedule.saturday')}</h3>
                    <p className="font-face-ms">{t('schedule.sat_act')}</p>
                </Carousel.Caption>
            </Carousel.Item>
            {/** 
            <Carousel.Item>
            <a href='/construction'>
                <Image fluid="True" src={sommarbrunch}/>
                </a>
                <Carousel.Caption>
                    <h3>{t('schedule.sunday')}</h3>
                    <p>{t('schedule.sun_act')}</p>
                </Carousel.Caption>
            </Carousel.Item>
            */}
        </Carousel>
        </Col>
        <Col></Col>
        </Row>
    </>
  );
}

function UnUsedSchedule() {
    const { t } = useTranslation();
    return (
        <>
          <main>
            <Card.Subtitle>{t('schedule.header')}</Card.Subtitle>
            <Tabs defaultActiveKey="thursday" id="schedule-tab" className="mb-3 justify-content-center">
                <Tab eventKey="thursday" title={t('schedule.thursday')}>
                    <Table responsive hover size="sm">
                        <thead>
                            <tr>
                                <th>{t('schedule.time')}</th>
                                <th>{t('schedule.activity')}</th>
                                <th>{t('schedule.where')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>17:00</td>
                                <td>{t('schedule.rehearsal')}</td>
                                <td>{t('schedule.church')}</td>
                            </tr>
                        </tbody>
                    </Table>
                </Tab>
                <Tab eventKey="friday" title={t('schedule.friday')}>
                    <Table responsive hover size="sm">
                        <thead>
                            <tr>
                                <th>{t('schedule.time')}</th>
                                <th>{t('schedule.activity')}</th>
                                <th>{t('schedule.where')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>10:00</td>
                                <td>{t('schedule.checkin')}</td>
                                <td>Skärva, reception</td>
                            </tr>
                            <tr>
                                <td>12:00</td>
                                <td>{t('schedule.lunch')}</td>
                                <td>Skärva, {t('schedule.kitchen')}</td>
                            </tr>
                            <tr>
                                <td>13:00</td>
                                <td>{t('schedule.decoration')}</td>
                                <td>Skärva, {t('schedule.partyvenue')}</td>
                            </tr>
                            <tr>
                                <td>19:00</td>
                                <td>{t('schedule.bbq')}</td>
                                <td>Skärva, {t('schedule.partytent')}</td>
                            </tr>
                        </tbody>
                    </Table>
                </Tab>
                <Tab eventKey="saturday" title={t('schedule.saturday')}>
    
                </Tab>
                <Tab eventKey="sunday" title={t('schedule.sunday')}>
    
    
                </Tab>
            </Tabs>
          </main>
        </>
      );
}

export default Schedule;
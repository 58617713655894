import logo from './logo.svg';
import './App.css';
import { Routes, Route, Link, useLocation } from "react-router-dom";
import { useTranslation, Trans} from "react-i18next";
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Navbar from 'react-bootstrap/Navbar';
import skarva from './img/skarva.jpg';
import karlskrona from './img/karlskrona_mindre.png';
import lf2 from './img/lf2.png';
import lf3 from './img/lf3.png';
import lf4 from './img/lf4.png';
import lf7 from './img/lf7.png';
import {
    ButtonGroup,
    Card,
    ToggleButton,
    Image,
    Tabs,
    Tab,
    Table,
    Stack,
    Row,
    Badge,
    Col,
    Carousel,
    Container, Form, Button, Offcanvas
} from 'react-bootstrap';
import {useEffect, useState} from "react";
import {getLanguage} from "./i18n";
import {fetchGuests, validateGuestCode} from "./api";
import Cookies from "universal-cookie";
import Info from "./Components/info";
import Accommodation from './Components/accommodation';
import Schedule from './Components/schedule';
import Home from './Components/home';
import UnderConstruction from './Components/construction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import Countdown from './Components/countdown';
import Vision from './Components/vision';
import RSVP from './Components/rsvp';
import WMT from './Components/wmt';
import Blog from './Components/blog';


function FormInput(props) {
    return (
        <Form.Group className="mb-3">
        <Form.Label htmlFor={props.for}>{props.desc}</Form.Label>
        <Form.Control name={props.name} type={props.type} value={props.value} className="form-control" id={props.for} onChange={props.onChange} disabled={props.disabled} />
        </Form.Group>);
}
/** Swag */
function App() {
  const cookies = new Cookies();

  const { t, i18n } = useTranslation();
  const location = useLocation();
  const currLang = cookies.get('lang')
  const [guest, setGuest] = useState(cookies.get('guest'));
  const [ langValue, setLangValue ] = useState(currLang ? currLang : 'sv-SE');

  
  if(langValue !== i18n.language ) {
    i18n.changeLanguage(langValue);
    
  }

  const page_map = {
    '/': t('home.header'),
    '/schedule': t('schedule.header'),
    '/construction': t('construction.header')
  }

  const subtitle_map = {
    '/': t('home.subtitle'),
    '/schedule': t('schedule.subtitle'),
    '/construction': t('construction.subtitle')
  }

  const logoutCallback = _ => {
      setGuest(null)
      cookies.remove('guest');
  }

  const langValues = {
    'sv-SE': 'SVENSKA',
    'en': 'ENGLISH'
    };

  const langCallback = (val) => {
    i18n.changeLanguage(val);
    setLangValue(val);
    cookies.set('lang', val, {path: '/'})
}


  return (
    <div className="App bg-light">
        <Row style={{overflow: 'hidden', position:'relative', height:'100%'}}> 
            <Image 
                className='banner-image'
                fluid 
                src={lf3}
                />
            <div>
                <h1 className='font-family-ms-bold banner-text'>
                    {t('home.header')}
                </h1>
                <h3 className='font-family-ms banner-subtitle'>
                    {t('home.subtitle')}
                </h3>
            </div>
        </Row>
        <Card bg="light" text="dark" className="text-center">
        <NavigationBar guest={guest} logoutCallback={logoutCallback} langValue={langValue} langValues={langValues} langCallback={langCallback}/>
            <Card.Body>
                <Routes>
                    <Route path='/' element={<Start/>}/>
                    <Route path="/info" element={<Info/>}/>
                    <Route path="/schedule" element={<Schedule/>}/>
                    <Route path="/rsvp" element={<RSVP/>}/>
                    <Route path="/accommodation" element={<Accommodation/>}/>
                    <Route path="/guestlist" element={<GuestList/>}/>
                    <Route path="/construction" element={<UnderConstruction/>}/>
                    <Route path="/wmt" element={<WMT/>}/>
                    <Route path="/vision" element={<Vision/>}/>
                </Routes>
            </Card.Body>
            
        </Card>
        
        <Row className='text-center' style={{overflow: 'hidden', position:'relative', height:'100%'}}>
            
            <Image className='banner-image' fluid src={lf7}/>
            <Countdown/>
        </Row>
    </div>
  );
}

function Start() {
    return (
        <div>
            <Home/>
            <hr></hr>
            <Blog/>
            <hr></hr>
            <Schedule/>
        </div>
    )
}

function LargeScreenNavbar(props) {
    const { t, i18n } = useTranslation();
    const location = useLocation();


    const pageScrollHandler = (e, val, location) => {
        let scrollTarget = document.getElementById(val);
        // only preventdefault IF route is on home page
        if(location.pathname === '/')
            e.preventDefault();
        scrollTarget && scrollTarget.scrollIntoView();
    }

    

    
    
    return (
        // NOTE: The hidden navlinks below
        <Row className="bg-body">
            <Col></Col>
            <Col md={6}>
      <Navbar sticky="top" bg="body" variant="light" key="md" expand="md">
            
          <Navbar.Brand href="/" onClick={e => pageScrollHandler(e, 'home')}>Felicia & Ludvig</Navbar.Brand>
          <Navbar.Toggle aria-controls="first-nav"/>
          <Navbar.Collapse id="first-nav">
          <Nav className="me-auto font-face-ms-bold">
                    <Nav.Item>
                    <Nav.Link href="/" onClick={e => pageScrollHandler(e, 'schedule', location)}>{t('nav.schedule')}</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                    <Nav.Link hidden href="/" onClick={e => pageScrollHandler(e, 'vision', location)}>{t('nav.vision')}</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                    <Nav.Link href="/rsvp">{t('nav.rsvp')}</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                    <Nav.Link  href="/info">{t('nav.information')}</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                    <Nav.Link hidden href="/wmt">WEDDING MANAGEMENT TEAM</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                    <Nav.Link href="/accommodation">{t('nav.accommodation')}</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                    <Nav.Link hidden="True" href="/guestlist">{t('nav.guestlist')}</Nav.Link>
                    </Nav.Item>
                </Nav>
                </Navbar.Collapse>
                </Navbar>
                </Col>
                <Col md={3}>
                <Navbar sticky="top" bg="body" variant="light" key="false" expand="false">
          <Navbar.Toggle aria-controls="offcanvas-navbar-nav"/>
          <Navbar.Offcanvas id="offcanvas-navbar-nav"
          aria-labelledby="offcanvas-navbar-label"
          placement="end">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title id="offcanvas-navbar-label">
                    {t('nav.header')}
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
            <Nav className="me-auto font-face-ms-bold">
                    <Nav.Item>
                    <Nav.Link href="/" onClick={e => pageScrollHandler(e, 'schedule', location)}>{t('nav.schedule')}</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                    <Nav.Link hidden href="/" onClick={e => pageScrollHandler(e, 'vision', location)}>{t('nav.vision')}</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                    <Nav.Link href="/info">{t('nav.information')}</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                    <Nav.Link href="/rsvp">{t('nav.rsvp')}</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                    <Nav.Link href="/wmt">WEDDING MANAGEMENT TEAM</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                    <Nav.Link href="/accommodation">{t('nav.accommodation')}</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link href="vision">{t('nav.vision')}</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                    <Nav.Link hidden="True" href="/guestlist">{t('nav.guestlist')}</Nav.Link>
                    </Nav.Item>
                </Nav>
                <Stack direction="horizontal" gap={1} style={{color: 'rgba(0, 0, 0, 0.65)'}}>
                <FontAwesomeIcon icon={faGlobe}/>
                <NavDropdown title={props.langValues[props.langValue]} id='langdropdown' className='font-face-ms-bold'>
                    {props.langValue === 'sv-SE' ?
                    <NavDropdown.Item as='button'  onClick={_ => props.langCallback('en')} className='font-face-ms'>English</NavDropdown.Item> :
                    <NavDropdown.Item as='button'  onClick={_ => props.langCallback('sv-SE')} className='font-face-ms'>Svenska</NavDropdown.Item> 
                    }
                </NavDropdown>
                
                </Stack>
                

                {
                        props.guest && props.guest !== "null" ?
                        <div>
                            <Col>
                              <Navbar.Text className="font-face-ms">
                                  {t('nav.signed')} {props.guest.firstname}
                              </Navbar.Text>
                            </Col>
                            <Col>
                              <Link className="font-face-ms" to="/" onClick={_ => props.logoutCallback()}>{t('logout')}</Link>
                              </Col>
                              </div>
                          : <></>
                  }


                </Offcanvas.Body>
          </Navbar.Offcanvas>
                
        </Navbar>
        </Col>
      </Row>
    );

}


function EverythingOffcanvasNavbar(props){
    const { t, i18n } = useTranslation();
    const location = useLocation();

    const pageScrollHandler = (e, val, location) => {
        let scrollTarget = document.getElementById(val);
        // only preventdefault IF route is on home page
        if(location.pathname === '/')
            e.preventDefault();
        scrollTarget && scrollTarget.scrollIntoView();
    }

    return (
        // NOTE: The hidden navlinks below
        <Row className="bg-body allcanvas">
            
      <Navbar sticky="top" bg="body" variant="light" key="md" expand="false">


            <Col xs></Col>
            <Col xs={6}>
          <Navbar.Brand href="/" onClick={e => pageScrollHandler(e, 'home')}>Felicia & Ludvig</Navbar.Brand>
            </Col>
            <Col xs>
          <Navbar.Toggle aria-controls="offcanvas-navbar-nav"/>
          </Col>
          <Navbar.Offcanvas id="offcanvas-navbar-nav"
          aria-labelledby="offcanvas-navbar-label"
          placement="end">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title id="offcanvas-navbar-label">
                    {t('nav.header')}
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
            <Nav className="me-auto font-face-ms-bold">
                    <Nav.Item>
                    <Nav.Link href="/" onClick={e => pageScrollHandler(e, 'schedule', location)}>{t('nav.schedule')}</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                    <Nav.Link hidden href="/" onClick={e => pageScrollHandler(e, 'vision', location)}>{t('nav.vision')}</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                    <Nav.Link  href="/info">{t('nav.information')}</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                    <Nav.Link href="/rsvp">{t('nav.rsvp')}</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                    <Nav.Link href="/wmt">WMT</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                    <Nav.Link href="/accommodation">{t('nav.accommodation')}</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link href="vision">{t('nav.vision')}</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                    <Nav.Link hidden="True" href="/guestlist">{t('nav.guestlist')}</Nav.Link>
                    </Nav.Item>
                </Nav>

                <Stack direction="horizontal" gap={1} style={{color: 'rgba(0, 0, 0, 0.65)'}}>
                <FontAwesomeIcon icon={faGlobe}/>
                <NavDropdown title={props.langValues[props.langValue]} id='langdropdown' className='font-face-ms-bold'>
                    {props.langValue === 'sv-SE' ?
                    <NavDropdown.Item as='button'  onClick={_ => props.langCallback('en')} className='font-face-ms'>English</NavDropdown.Item> :
                    <NavDropdown.Item as='button'  onClick={_ => props.langCallback('sv-SE')} className='font-face-ms'>Svenska</NavDropdown.Item> 
                    }
                </NavDropdown>
                
                </Stack>
                

                {
                        props.guest && props.guest !== "null" ?
                        <div>
                            <Col>
                              <Navbar.Text className="font-face-ms">
                                  {t('nav.signed')} {props.guest.firstname}
                              </Navbar.Text>
                            </Col>
                            <Col>
                              <Link className="font-face-ms" to="/" onClick={_ => props.logoutCallback()}>{t('logout')}</Link>
                              </Col>
                              </div>
                          : <></>
                  }


                </Offcanvas.Body>
          </Navbar.Offcanvas>
          
        </Navbar>
        
        
      </Row>
    );

}



function NavigationBar(props) {

    // React hooks to make sure we use the correct Navbar
    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
          width,
          height
        };
      }
      
       function useWindowDimensions() {
        const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
      
        useEffect(() => {
          function handleResize() {
            setWindowDimensions(getWindowDimensions());
          }
      
          window.addEventListener('resize', handleResize);
          return () => window.removeEventListener('resize', handleResize);
        }, []);
      
        return windowDimensions;
      }
      
      let { height, width } = useWindowDimensions();

    return (
        width > 870 ? // Empirically found 
        <LargeScreenNavbar guest={props.guest} logoutCallback={props.logoutCallback} langValue={props.langValue} langCallback={props.langCallback} langValues={props.langValues}/> : 
        <EverythingOffcanvasNavbar guest={props.guest} logoutCallback={props.logoutCallback} langValue={props.langValue} setLangValue={props.setLangValue} langValues={props.langValues} langCallback={props.langCallback}/>
    );
}


function OldRSVP(props) {
    const { t, i18n } = useTranslation();
    const [whoEnglish, setWhoEnglish] = useState();
    const [whoSwedish, setWhoSwedish] = useState();

    let guest = props.guest;
  return (
    <>
        <Card.Subtitle>{t('rsvp.header')}</Card.Subtitle>
        <Card.Text as="div">
            {
                !guest.answered ? t('rsvp.text') :
                    <div>
                    {t('rsvp.edit')}<br/>
                    {
                        t('rsvp.reply')}{guest.will_attend ?
                        <Badge bg="success">{t('rsvp.positive')}</Badge> :
                        <Badge bg="danger">{t('rsvp.negative')}</Badge>
                    }
                    </div>
            }
        </Card.Text>
              <Form onSubmit={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                }}>
                  <Row>
                      <Col>
                          <FormInput for="firstname" name="firstname" desc={t('rsvp.firstname')} type="text" value={guest.firstname} disabled={true}/>
                      </Col>
                      <Col>
                          <FormInput for="lastname" name="lastname" desc={t('rsvp.lastname')} type="text" value={guest.lastname} disabled={true}/>
                      </Col>
                  </Row>
                        <FormInput for="who_english" name="who_english" desc={t('rsvp.who_english')} type="text" value={whoEnglish} onChange={event => setWhoEnglish(event.target.value)}/>
                        <FormInput for="who_swedish" name="who_swedish" desc={t('rsvp.who_swedish')} type="text" value={whoSwedish} onChange={event => setWhoSwedish(event.target.value)} disabled={i18n.language === 'en'}/>
                        <Form.Group className="mb-3">
                            <Form.Check type="checkbox" label="Vegetarian"/>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Check type="checkbox" label="Vegan"/>
                        </Form.Group>
                  <Form.Group>
                      <Form.Label htmlFor="allergies">{t('rsvp.allergies')}</Form.Label>
                      <Form.Control name="allergies" as="textarea" rows={3}/>
                  </Form.Group>
                  <Form.Group>
                      <Form.Check inline type="radio" name="answer" id="answer-positive" label={t('rsvp.positive')}/>
                      <Form.Check inline type="radio" name="answer" id="answer-negative" label={t('rsvp.negative')}/>
                  </Form.Group>
                      <Button variant="primary" type="submit" className="btn btn-primary m-2" >{t('rsvp.save')}</Button>
                  </Form>
    </>
  );
}

function GuestList() {
    const { t } = useTranslation();
    const [ isLoaded, setLoaded ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState();
    const [ guestList, setGuestList ] = useState([]);


    useEffect(() => {
        fetchGuests().then(result => {
            setLoaded(result.isLoaded);
            setErrorMessage(result.errorMessage);
            setGuestList(result.guests);
        });
    }, []);

  return isLoaded ? (
    <>
      <main>
        <Card.Subtitle>{t('guestlist.header')}</Card.Subtitle>
        <Table responsive hover size="sm">
                    <thead>
                        <tr>
                            <th>{t('guestlist.name')}</th>
                            <th>{t('guestlist.who')}</th>
                            <th>{t('guestlist.table')}</th>
                            <th>{t('guestlist.room')}</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        guestList.map((guest, idx) => (
                            <tr key={idx}>
                                <td>{guest.firstname} {guest.lastname}</td>
                                <td>{getLanguage() === "en" ? guest.who_english : guest.who_swedish}</td>
                                <td>{guest.table_number}</td>
                                <td>{guest.room}</td>
                            </tr>
                        ))
                    }
                    </tbody>
                </Table>
      </main>
    </>
  ) : <></>;
}

export default App;

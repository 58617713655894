import { useTranslation, Trans} from "react-i18next";
import {
    Card,
    Row,
    Col,
} from 'react-bootstrap';
import {useEffect, useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPersonDigging } from '@fortawesome/free-solid-svg-icons'

function UnderConstruction() {
    const { t } = useTranslation();
    return (
        <main>
          <h2 className='font-face-ms'>{t('construction.header')}</h2>
          <Row>
            <Col></Col>
            <Col md={8}>
            <FontAwesomeIcon icon={faPersonDigging} size="2xl"/>
          <div className="mx-auto font-face-ms card-text">
              <p>{t('construction.ingress')}</p>
          </div>
          </Col>
          <Col></Col>
          </Row>
          <Row>
          </Row>
          <Row>
          </Row>

      </main>
    )
}

export default UnderConstruction;
import { useTranslation, Trans} from "react-i18next";
import {
    ButtonGroup,
    Card,
    ToggleButton,
    Image,
    Tabs,
    Tab,
    Table,
    Stack,
    Row,
    Badge,
    Col,
    Container, Form, Button
} from 'react-bootstrap';
import { Link } from "react-router-dom";

function Info() {
    const { t } = useTranslation();
  return (
    <>
      <main>
      <h2 className="font-face-ms">{t('nav.information')}</h2>
        <Row>
          <Col/>
          <Col md={6} className="font-face-ms">
            <p>{t('info.intro')} <b>felicialudvig@gmail.com</b></p>
            <h4 className="h3_cust">{t('info.karlskrona')}</h4>
            <p>{t('info.karlskrona_answer')}</p>
            <h4 className="h3_cust">{t('info.plus_one')}</h4>
            <p>{t('info.plus_one_answer')}</p>
            <h4 className="h3_cust">{t('info.children')}</h4>
            <p>{/** Lol, nej */}{t('info.children_answer')}<br/>{t('info.children_answer2')}<br/>{t('info.children_answer3')}</p>
            <h4 className="h3_cust">{t('info.spex')}</h4>
            <p>{t('info.spex_answer')} <b>{t('info.spex_email')}</b>!</p>
            <h4 className="h3_cust">{t('info.accommodation')}</h4>
            <p>{t('info.accommodation_answer')}</p>
            <h4 className="h3_cust">{t('info.support')}</h4>
            <p>{t('info.support_answer')}</p>
            <h4 className="h3_cust">{t('info.dresscode')}</h4>
              <Table className="infotable" borderless>
                <tbody>
                <tr>
                  <td><b>{t('info.dresscode_ladies')}</b></td>
                  <td>{t('info.dresscode_friday')}</td>
                  <td>{t('info.dresscode_ladies1')}</td>
                </tr>
                <tr>
                  <td>
                  </td>
                  <td>{t('info.dresscode_saturday')}</td>
                  <td>{t('info.dresscode_ladies2')}</td>
                </tr>
                <tr>
                  <td><b>{t('info.dresscode_gents')}</b></td>
                  <td>{t('info.dresscode_friday')}</td>
                  <td>{t('info.dresscode_gents1')}</td>
                </tr>
                <tr>
                  <td></td>
                  <td>{t('info.dresscode_saturday')}</td>
                  <td>{t('info.dresscode_gents2')}</td>
                </tr>
                </tbody>
              </Table>
              <p>{t('info.dresscode_general')}</p>
            <h4 className="h3_cust">{t('info.wedding_gift')}</h4>
            <p>{t('info.wedding_gift_answer')}</p>
            <h4 className="h3_cust">{t('info.parking')}</h4>
            <p>{t('info.parking_answer')}</p>
            <h4 className="h3_cust">{t('info.what_do')}</h4>
            {t('info.what_do_answer1')}<br/><br/>
            {t('info.what_do_answer2')} <a href="https://karlskronaskargardsfest.se/">Skärgårdsfesten</a> {t('info.what_do_answer21')} {t('info.what_do_answer22')}<br/><br/>
            <Row>
              <Col sm/>
              <Col sm>
            
            <b>{t('info.restaurants_dinner')}</b>
            
            <ul className="justify-left">
              <li>Bette</li>
              <li>Antonios</li>
              <li>Pop-up:en @ Fisktorget</li>
              <li>Montmartre</li>
              <li>Vinberga Vinkiosk</li>
            </ul>
            <b>{t('info.restaurants_lunch')}</b>
            <ul className="justify-left">
              <li>Nya Skafferiet</li>
              <li>Elsa's</li>
              <li>{t('info.blomlofs')}</li>
            </ul>
            
            
            </Col>
            <Col sm/>
            </Row>
            {t('info.michels')}
            
          
          </Col>
          <Col/>
        </Row>
      </main>
    </>
  );
}

export default Info;
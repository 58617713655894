import { useTranslation} from "react-i18next";
import {
    Row,
    Col,
    Image,
    Carousel,
} from 'react-bootstrap';
import skarva from './../img/skarva_rum.png';
import statt from './../img/statt_rum.jpg';
import statt_hotel from './../img/statt_hotel.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";

function NextIcon() {
  return (<FontAwesomeIcon icon={faChevronRight} size='2xl' style={{color: '#ffffff', width: '4rem',}}/>);
}

function PrevIcon() {
  return (<FontAwesomeIcon icon={faChevronLeft} size='2xl' style={{color: '#ffffff', width: '4rem'}}/>);
}

function Accommodation() {
    const { t } = useTranslation();
  return (
    <>
      <main>
      <h2 className="font-face-ms">{t('nav.accommodation')}</h2>
        <Row>
          <Col/>
          <Col md={6} className="font-face-ms">
            <h3>Indelning</h3>
            <p>I listan nedan ser du hur vi har delat in boendet. Om vi har fel information eller om något har ändrats, <b>maila oss direkt på felicialudvig@gmail.com!</b><br/>
            Vilket gäng vi blir! Vi är så taggade!!</p>
            <iframe src="https://docs.google.com/spreadsheets/d/e/2PACX-1vTB7IeA9VJnRriKHdKg_h4g4elaMkIR9429stg2Ihl3zGAmPKQHTkp2sapr4SiKSyQuiMK_ZgyfZwcx/pubhtml?widget=true&amp;headers=false" height="2000"></iframe>
            <p>{t('accommodation.intro')}</p>
            <ul>
              <li>{t('accommodation.statt_info')}</li>
              <li>{t('accommodation.skarva_info')}</li>
            </ul>
            <p>{t('accommodation.general_info1')} {t('accommodation.general_info2')} <b>{t('accommodation.general_info3')}</b></p>
              <p>{t('accommodation.statt_more')} <a href="https://www.firsthotels.se/hotell/sverige/karlskrona/first-hotel-statt/">{t('accommodation.here')}</a>!</p>
              <p>{t('accommodation.skarva_more')} <a href="https://skarvaherrgard.com/">{t('accommodation.here')}</a>!</p>

            <Carousel className='font-face-ms-bold' fade >  
                <Carousel.Item className='carousel-item-cust'>
                    <Image fluid src={skarva}/>
                    <Carousel.Caption>
                        <h1 className='font-face-ms'>{t('accommodation.room')} Skärva Herrgård</h1>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item className='carousel-item-cust'>
                    <Image fluid src={statt}/>
                    <Carousel.Caption>
                        <h1 className='font-face-ms'>{t('accommodation.room')} First Hotel Statt</h1>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item className='carousel-item-cust'>
                    <Image fluid src={statt_hotel}/>
                    <Carousel.Caption>
                        <h1 className='font-face-ms'>First Hotel Statt</h1>
                    </Carousel.Caption>
                </Carousel.Item>
                
            </Carousel>
            
          
          </Col>
          <Col/>
        </Row>
      </main>
    </>
  );
}

export default Accommodation;
import { useTranslation, } from "react-i18next";
import {
    Image,
    Carousel,
    Col,
    Row
} from 'react-bootstrap';
import skarva from './../img/skarva.jpg';
import karlskrona from './../img/karlskrona_mindre.png';
import statt from './../img/statt.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";


function NextIcon() {
    return (<FontAwesomeIcon icon={faChevronRight} size='2xl' style={{color: '#ffffff', width: '4rem',}}/>);
}

function PrevIcon() {
    return (<FontAwesomeIcon icon={faChevronLeft} size='2xl' style={{color: '#ffffff', width: '4rem'}}/>);
}

function Vision() {
    const { t } = useTranslation();
  return (
    <> 
        <main id="vision">
            <Row className="mb-5">
        <Carousel className='font-face-ms-bold  carousel-cust' fade nextIcon={<NextIcon/>} prevIcon={<PrevIcon/>} >
                <Carousel.Item className='carousel-item-cust'>
                    <Image className='' fluid src={karlskrona}/>
                    <Carousel.Caption className='carousel-caption-cust'>
                        {/*<h1 className='font-face-ms-bold'>{t('vision.cap1')}</h1>*/}
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item className='carousel-item-cust'>
                    <Image fluid src={skarva}/>
                    <Carousel.Caption className='carousel-caption-cust'>
                        {/*<h1 className='font-face-ms-bold'>{t('vision.cap2')</h1>*/}
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item className='carousel-item-cust'>
                    <Image fluid src={statt}/>
                    <Carousel.Caption className='carousel-caption-cust'>
                        {/*<h1 className='font-face-ms-bold'>{t('vision.cap2')</h1>*/}
                    </Carousel.Caption>
                </Carousel.Item>
                
            </Carousel>
            </Row>
                <h2 className='font-face-ms'>{t('nav.vision')}</h2>
                <Row>
                    <Col></Col>
                    <Col md={6}>
                        <div className="font-face-ms card-text mx-auto">
                            <p>{t('vision.text1')} <b>{t('vision.vision')}</b> {t('vision.text2')}</p>
                            <p>{t('vision.text3')} {t('vision.text4')}</p>
                        </div>
                    </Col>
                    <Col></Col>
                </Row>
        </main>
                
    </>
  );
}

export default Vision;